
import {
    defineComponent, reactive, ref, Ref
} from 'vue';
import {
    listPagination, RequestData
} from '@/components/common/list';
import { format } from '@/methods/date';
import router from '@/router';
import { superBaseRouter, superSubRouter } from '@/router/data';
import { account, accountType, order } from '@/data';
import {
    headers,
    payTypeOptions,
    typeOptions,
    statusOptions,
    checkNameForProject
} from './util';

export default defineComponent({
    components: {
        listPagination
    },
    setup() {
        const formData = reactive({
            Type: 'all',
            Status: 'all',
            PayType: 'all',
            Key: '',
            BeginTime: '',
            EndTime: '',
            Installer: 'all',
            Community: 'all'
        });

        const installerOptions: Ref<accountType.GetAllInstallerCommunity> = ref([]);
        const communityOptions: Ref<Array<{ ID: string; Name: string; Grade: string }>> = ref([]);
        account.getAllInstallerCommunity((data: accountType.GetAllInstallerCommunity) => {
            installerOptions.value = data;
        });
        const changeInstaller = (ID: string) => {
            formData.Community = 'all';
            installerOptions.value.forEach((item) => {
                if (item.ID === ID) {
                    communityOptions.value = checkNameForProject([...item.Community, ...item.Office]);
                }
            });
        };

        const updateToList = ref(0);
        const requestData: RequestData = reactive({
            url: 'getallorderlist',
            param: { ...formData }
        });

        const search = () => {
            const data = { ...formData };
            data.BeginTime = format(data.BeginTime);
            data.EndTime = format(data.EndTime);
            requestData.param = data;
            updateToList.value += 1;
        };

        const goExport = () => {
            router.push(`/${superBaseRouter}/${superSubRouter.exportOrder}`);
        };

        const goInfo = (value: { ID: string }) => {
            router.push(`/${superBaseRouter}/${superSubRouter.paymentInfo}?id=${value.ID}`);
        };

        return {
            headers,
            requestData,
            payTypeOptions,
            typeOptions,
            statusOptions,
            installerOptions,
            formData,
            changeInstaller,
            communityOptions,
            updateToList,
            search,
            goExport,
            goInfo,
            order
        };
    }
});
